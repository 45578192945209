@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .justify-safe-center {
    justify-content: safe center;
  }

  .items-safe-center {
    align-items: safe center;
  }
}

@layer base {
  :root {
    --background: 250 20% 98%;
    --foreground: 250 20% 3%;
    --card: 250 20% 94%;
    --card-foreground: 250 10% 4%;
    --popover: 250 20% 96%;
    --popover-foreground: 250 10% 4%;
    --primary: 250 6% 10%;
    --primary-foreground: 250 20% 98%;
    --secondary: 230 30% 96%;
    --secondary-foreground: 230 6% 5%;
    --muted: 250 20% 90%;
    --muted-foreground: 250 8% 30%;
    --accent: 250 5% 90%;
    --accent-foreground: 240 6% 10%;
    --destructive: 0 85% 60%;
    --destructive-foreground: 0 0% 98%;
    --border: 250 10% 86%;
    --input: 250 6% 88%;
    --ring: 250 6% 10%;
    --radius: 0.3rem;
  }

  .dark {
    --background: 250 20% 1%;
    --foreground: 250 20% 98%;
    --card: 250 20% 2%;
    --card-foreground: 0 0% 98%;
    --popover: 250 20% 3%;
    --popover-foreground: 0 0% 98%;
    --primary: 250 20% 96%;
    --primary-foreground: 250 6% 5%;
    --secondary: 250 4% 16%;
    --secondary-foreground: 0 0% 98%;
    --muted: 250 4% 15.9%;
    --muted-foreground: 250 5% 65%;
    --accent: 250 4% 16%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 0 0% 98%;
    --border: 250 4% 16%;
    --input: 250 4% 16%;
    --ring: 250 5% 84%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
